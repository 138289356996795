import axios from "axios";
import Vue from "vue";
axios.interceptors.request.use(function (config) {
  if (Vue.$cookies.isKey("hourglass_token")) {
    config.headers.Authorization = `Bearer ${Vue.$cookies.get(
      "hourglass_token"
    )}`;
  }

  config.headers.common["API-KEY"] = process.env.VUE_APP_API_KEY;
  config.headers.common["Access-Control-Allow-Origin"] = "*";
  return config;
});
// Add API response
axios.interceptors.response.use(function (response) {
  return Promise.resolve(response.data);
});

let baseURL = "";
if (process.env.NODE_ENV == "development") {
  baseURL = "https://api-alothailand-mobile-prod.d-dots.com";
} else {
  let location = window.location.hostname;
  let brand = location.split("-")[0];
  var subdomain = location.split(".");
  subdomain.shift();
  subdomain = subdomain.join(".");
  baseURL = `${window.location.protocol}//api-${brand}-mobile-prod.${subdomain}`;
}

const instance = axios.create({
  baseURL: baseURL,
  responseType: "json",
  timeout: 20000
});

instance.interceptors.request.use(function (config) {
  if (Vue.$cookies.isKey("hourglass_token")) {
    config.headers.Authorization = `Bearer ${Vue.$cookies.get(
      "hourglass_token"
    )}`;
  }

  config.headers.common["API-KEY"] = process.env.VUE_APP_API_KEY;
  config.headers.common["Access-Control-Allow-Origin"] = "*";
  return config;
});
// Add API response
instance.interceptors.response.use(function (response) {
  return Promise.resolve(response.data);
});
Vue.prototype.$Axios = axios;
Vue.prototype.axios = instance;

export { instance };
