import Vue from "vue";
import VueRouter from "vue-router";
const brand = process.env.VUE_APP_BRAND;
Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Index",
  //   redirect: "/profile",
  //   component: () => import("@/views/Home.vue"),
  //   meta: {
  //     title: brand
  //   }
  // },
  {
    path: "/",
    name: "Index",
    // redirect: "/profile",
    component: () => import("@/views/redirect.vue"),
    meta: {
      title: brand
    }
  },
  {
    path: "/validatetelephone",
    name: "validatetelephone",
    component: () => import("@/views/page/validateTelephone/index.vue"),
    meta: {
      title: `${brand}`
    }
  },
  {
    path: "/confirmotp",
    name: "confirmotp",
    component: () => import("@/views/page/validateTelephone/confirmOTP.vue"),
    meta: {
      title: `${brand}`
    }
  },
  {
    path: "/coupon",
    name: "coupon-redeem",
    component: () => import("@/views/page/coupon/Index.vue"),
    meta: {
      title: brand
    }
  },
  {
    path: "/coupon/:couponUser/:couponKey",
    name: "coupon-redeem-detail",
    component: () => import("@/views/page/coupon/detail/Detail.vue"),
    meta: {
      title: brand
    }
  },
  {
    path: "/couponhistory/:id",
    name: "coupon-history-detail",
    component: () => import("@/views/page/privilege/detail/CouponHistory.vue"),
    meta: {
      title: brand
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/page/register/index.vue"),
    meta: {
      title: brand
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/page/profile/index.vue"),
    meta: {
      title: brand
    }
  },
  {
    path: "/editprofile",
    name: "editprofile",
    component: () => import("@/views/page/editprofile/index.vue"),
    meta: {
      title: brand
    }
  },
  {
    path: "/privilege",
    name: "privilege",
    component: () => import("@/views/page/privilege/index.vue"),
    meta: {
      title: brand
    }
  },
  {
    path: "/privilege/:privilegeId",
    name: "privilege-detail",
    component: () => import("@/views/page/privilege/detail/Detail.vue")
  },
  {
    path: "/privilegehistory/:id",
    name: "privilege-history-detail",
    component: () => import("@/views/page/privilege/detail/DetailHistory.vue")
  },
  {
    path: "/privilege/set/:id",
    name: "privilege-set-detail",
    component: () => import("@/views/page/privilege/detail/PrivilegeSet.vue")
  },

  {
    path: "/point",
    name: "point",
    component: () => import("@/views/page/point/index.vue"),
    meta: {
      title: brand
    }
  },
  {
    path: "/branchPoint",
    name: "Branch Point",
    component: () => import("@/views/page/point/branch.vue"),
    meta: {
      title: brand
    }
  },
  {
    path: "/point/log/:mode",
    name: "Branch Point",
    component: () => import("@/views/page/point/log.vue"),
    meta: {
      title: brand
    }
  },

  {
    path: "/review",
    name: "review",
    component: () => import("@/views/page/review/index.vue"),
    meta: {
      title: brand
    }
  },
  {
    path: "/telephone",
    name: "editprofile-telephone",
    component: () => import("@/views/page/editprofile/telephone.vue"),
    meta: {
      title: `${brand}`
    }
  },
  {
    path: "/confirmotpedit",
    name: "confirmotpedit",
    component: () => import("@/views/page/editprofile/confirmotpedit.vue"),
    meta: {
      title: `${brand}`
    }
  },
  {
    path: "/news",
    name: "news",
    component: () => import("@/views/page/news/index.vue")
  },
  {
    path: "/news/:newsId",
    name: "news-detail",
    component: () => import("@/views/page/news/detail/Detail.vue")
  },
  {
    path: "/reward",
    name: "reward",
    component: () => import("@/views/page/reward/index.vue")
  },
  {
    path: "/reward/:rewardId",
    name: "reward-detail",
    exact: true,
    component: () => import("@/views/page/reward/detail/Detail.vue")
  },
  {
    path: "/reward/privilege/:rewardId",
    name: "reward-detail",
    exact: true,
    component: () =>
      import("@/views/page/reward/detail/DetailRewardPrivilege.vue"),
    meta: {
      title: `Reward`
    }
  },
  {
    path: "/rewardhistory",
    name: "reward-history",
    component: () => import("@/views/page/reward/detail/History.vue")
  },
  {
    path: "/rewardhistory/:id",
    name: "reward-history-detail",
    exact: true,
    component: () => import("@/views/page/reward/detail/DetailHistory.vue")
  },
  {
    path: "/editaddress/:id",
    name: "reward-edit-address",
    component: () => import("@/views/page/reward/detail/DetailEditAddress.vue"),
    meta: {
      title: `${brand}`
    }
  },
  {
    path: "/form",
    name: "campaign form",
    component: () => import("@/views/page/campaignform/index.vue"),
    meta: {
      title: `Form`
    }
  },
  {
    path: "/setting",
    name: "Setting PDPA",
    component: () => import("@/views/page/settingpdpa/index.vue"),
    meta: {
      title: `Setting`
    }
  },
  {
    path: "/policy",
    name: "policy",
    component: () => import("@/views/page/policy/index.vue"),
    meta: {
      title: `Policy`
    }
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/page/terms/index.vue"),
    meta: {
      title: `Terms`
    }
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    component: () => import("@/views/page/marketplace"),
    meta: {
      title: `Marketplace`
    }
  },
  {
    path: "/connectMarketplace",
    name: "Marketplace",
    component: () => import("@/views/page/marketplace/connectMarketplace"),
    meta: {
      title: `Marketplace`
    }
  },
  {
    path: "/game",
    name: "Marketplace",
    component: () => import("@/views/page/game"),
    meta: {
      title: `Redirect Game`
    }
  },
  {
    path: "/stampcard",
    name: "Stampcard",
    component: () => import("@/views/page/stampcard"),
    meta: {
      title: `Stamp Card`
    }
  },
  {
    path: "/stampcard/detail/:id",
    name: "Stampcard Detail",
    exact: true,
    component: () => import("@/views/page/stampcard/detail"),
    meta: {
      title: `Stamp Card`
    }
  },
  {
    path: "/booking",
    name: "booking",
    component: () => import("@/views/page/booking"),
    meta: {
      title: `Booking`
    }
  },
  {
    path: "/booking/detail/:id",
    name: "booking Detail",
    exact: true,
    component: () => import("@/views/page/booking/detail"),
    meta: {
      title: `Booking Detail`
    }
  },
  {
    path: "/member/:id",
    name: "Member Description",
    component: () => import("@/views/page/member"),
    meta: {
      title: `${brand}`
    }
  },
  {
    path: "/kis",
    name: "KIS Transaction",
    component: () => import("@/views/page/kis"),
    meta: {
      title: `KIS Transaction`
    }
  },
  {
    path: "/transaction-summary",
    name: "Transaction-Summary",
    component: () => import("@/views/page/transactionHistory"),
    meta: {
      title: `Transaction Summary`
    }
  },
  {
    path: "/transaction-summary/:id",
    name: "Transaction-Summary-Detail",
    component: () => import("@/views/page/transactionHistory/detail"),
    meta: {
      title: `YOUR ORDER SUMMERY`
    }
  },
  {
    path: "/invite",
    name: "Friend Get Friend",
    component: () => import("@/views/page/FriendGetFriend"),
    meta: {
      title: `Friend Get Friend`
    }
  },
  {
    path: "/invite/list",
    name: "Friend Get Friend",
    component: () => import("@/views/page/FriendGetFriend/inviteList.vue"),
    meta: {
      title: `Friend Get Friend`
    }
  },
  {
    path: "/transaction",
    name: "transaction",
    component: () => import("@/views/page/transaction"),
    meta: {
      title: `Transaction`
    }
  },

  {
    path: "/:redeemId/:transactionCode",
    name: "redirect transaction",
    exact: true,
    component: () => import("@/views/page/redirectTransaction")
    // redirect: to => {
    //   return {
    //     path: "/transaction",
    //     query: {
    //       code: to.params.transactionCode,
    //       redeem_id: to.params.redeemId
    //     }
    //   };
    // }
  },
  {
    path: "/:pathName/:redeemId/:transactionCode",
    name: "redirect transaction",
    exact: true,
    component: () => import("@/views/page/redirectTransaction")
    // redirect: to => {
    //   return {
    //     path: "/transaction",
    //     query: { code: to.params.transaction_code }
    //   };
    // }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = brand;
  } else if (previousNearestWithMeta) {
    document.title = brand;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    el => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));
  document.title = brand;
  next();
});
export default router;
